import React, { Component, Suspense } from 'react';
import { USBGrid, USBColumn } from '@usb-shield/components-react';
import { v4 as uuidv4 } from 'uuid';
import { endpoints } from '../../../config/connections';

import './aw-spinner.css'

const EnrollmentModule = React.lazy(() =>
  import('@usb-sharedauth/react-cdc-enrollment-modules/dist/cjs/CdcEnrollmentModule')
);

const getCorrelationID = () => {
  const correlationId = uuidv4();
  typeof sessionStorage !== 'undefined'
    ? sessionStorage.setItem('correlationId', correlationId)
    : '';
  return correlationId;
};

const configHeader = {
  'Tenant-ID': 'USB',
  'App-ID': 'CDC',
  'Channel-ID': 'Web',
  'App-Version': '1',
  AK: `${endpoints.configAK}`,
  'Correlation-ID': getCorrelationID(),
};

const configSettings = {
  transmitConfigs: {
    URL: endpoints.transmit,
    APP_ID: 'cdc_web',
    policyIds: {
      CUSTOMER_ENROLLMENT: 'customer_enrollment',
    },
    policyParams: {
      routingKey: endpoints.authRoutingKey,
      appId: 'CDC',
      tenantId: 'USB',
      channelId: 'web',
      appVersion: '1',
      customData:{

                    branchId: "branchId"

                }
    },
  },
  urls: {
    customerEnrollment: {
      CREATE_CREDENTIALS_API: endpoints.createCredentialsApi,
    },
  },
};

const redirectURL = {
  CDCLandingPage: `${endpoints.redirect}/ReportingDashboard`,
};

class Login extends Component {
  constructor() {
    super();
  }

  redirectToLoginPortal = () => {
    window.location.href = '/';
  };

  parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = decodeURIComponent(
      atob(base64Url)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );

    return JSON.parse(base64);
  };

  onTransmitInitSuccess = () => {};

  onTransmitInitFailure = () => {};

  onTransmitAuthorizationFailure = () => {};

  moduleName = (value) => {
    this.setState({ redirect: value });
  };

  onTransmitAuthorizationSuccess = (response) => {
    const { CDCLandingPage } = redirectURL;
    sessionStorage.clear();
    localStorage.setItem('tok', response.token);
    sessionStorage.setItem('tok', response.token);
    sessionStorage.setItem('loggedUser', this.parseJwt(response.token).sub);
    sessionStorage.setItem('assignedUser', this.parseJwt(response.token).sub);
    sessionStorage.setItem('type', this.parseJwt(response.token).pid);
    window.location.href = CDCLandingPage;
  };

  render() {
    return (
      <>
        <USBGrid addClasses="content-frame">
          <USBColumn largeSpan={16}>
            <div className="page-content-login">
              <div className="page-content">
                <div id="test-spinner" />
                <Suspense fallback={<div>Loading...</div>}>
                  <EnrollmentModule
                    configApiHeaders={configHeader}
                    configAPIURL={endpoints.configApiURL}
                    configSettings={configSettings}
                    enrollmentLoginPortalClick={this.redirectToLoginPortal}
                  />
                </Suspense>
              </div>
            </div>
          </USBColumn>
        </USBGrid>
      </>
    );
  }
}

export default Login;
