import React from 'react';
import { withRouter } from "react-router";
import { USBRadio, USBButton, USBProgressIndicator, USBNotification, USBCheckbox, USBSearchInput } from '@usb-shield/components-react/dist/cjs';
import { connect } from "react-redux";
import componentConfig from '../../config/component-config.json'
import '../../App.css';
import Axios from 'axios';
import { getDocumentmgmtServiceURL, getConstants, getDocumentmgmtServiceURLuser } from '../../config/connections';
import NavigateBack from '../../components/shared/navigateBack';
import LoadingHelper from '../../utilities/loading-helper';
import AssignPermissionHelper from './assign-permission-helper';
import { UserRoleConfig } from '../../config/user-role-config.js';
import ProgressIndicator from '../../components/shared/ProgressIndicator';
import { EntitlementDetails } from '../profile-edit-review/get-entitlement-details'
import { entitlementUpdateThunk } from '../../shared/thunk/entitlements-update-thunk';
import { transmitIframeMessage } from '../../components/utility/transmit-message';

class AssignPermissions extends React.Component {

    maxEntityLength = 12;
    constructor(props) {
        super(props);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onContinue = this.onContinue.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.handleSelectAll = this.handleSelectAll.bind(this);
        this.onJumpLinkClick = this.onJumpLinkClick.bind(this);
        this.payload = new Object();
        this.isTopLevelProfileError = false;
        this.isPermissionUpdateSuccess = false;
        this.isPermissionUpdateFailed = false;
        this.getEntitlement = new EntitlementDetails();

        this.state = {
            loading: true,
            isLegalEntitySelected: false,
            isDealSelected: false,
            isAssignToAllSelected: false,
            isApiCallError: false,
            isAllLegalChecked: false,
            isAllDealsChecked: false,
            isAllLegalDocumentsChecked: false,
            isAllDealDocumentsChecked: false,
            initIsChecked: {
            },
            dealsList: [],
            legalList: [],
            legalEntityDocList: [],
            dealDocumentList: [],
            search: {
                legalEntities: '',
                dealEntities: '',
                legalEntityDocList: '',
                dealDocList: ''
            },

            isTopLevelError: false,
            isLegalEntityError: false,
            isLegalEntityFieldError: false,
            isLegalEntityDocFieldError: false,
            isDealError: false,
            isDealFieldError: false,
            isDealDocFieldError: false,
            unlistedVendorSaveFromManageUserList: false
            // selectAllTempReload: false // This is used as hack for temp solution to fix for shield check box component
        }
    }


    onCancelClick = (e) => {
        let path = '/ManageUser/';
        this.props.history.push(path);
    }

    saveCurrentStateToRedux = () => {
        this.props.dispatch({
            type: "SET_ENTITLEMENT",
            payload: this.payload
        });
        this.props.dispatch({
            type: "SET_ENTITY",
            payload: {
                isLegalEntitySelected: this.state.isLegalEntitySelected,
                isDealSelected: this.state.isDealSelected,
                isAssignToAllSelected: this.state.isAssignToAllSelected
            }
        });
        this.props.dispatch({
            type: "SET_ALL_ENTITY_SELECTED",
            payload: {
                isDealAllSelected: this.state.isAllDealsChecked,
                isLegalAllSelected: this.state.isAllLegalChecked,
                isCategoryAllSelected: this.state.isAllDealDocumentsChecked || this.state.isAllLegalDocumentsChecked
            }
        });
    }

    onContinue = (e) => {
        this.isPermissionUpdateSuccess = false;
        if (this.state.isLegalEntitySelected || this.state.isDealSelected || this.state.isAssignToAllSelected) {
            this.payload = null;
            if (this.state.isLegalEntitySelected) {
                this.payload = {
                    selectedLegalEntityList: this.state.legalList.filter(ll => ll.selected),
                    selectedEntiyDocumentList: this.state.legalEntityDocList.filter(dl => dl.selected)
                }
            } else if (this.state.isDealSelected) {
                this.payload = {
                    selectedDealEntityList: this.state.dealsList.filter(dl => dl.selected),
                    selectedDealDocumentList: this.state.dealDocumentList.filter(dl => dl.selected)
                }
            } else if (this.state.isAssignToAllSelected) {
                this.payload = {
                    selectedLegalEntityList: undefined,
                    selectedDealEntityList: undefined,
                    selectedEntiyDocumentList: undefined,
                    selectedDealDocumentList: undefined
                }
            }
            if (this.payload) {
                this.saveCurrentStateToRedux();
                if (this.state.isLegalEntitySelected) {
                    if ((!this.state.isAllLegalChecked && !this.payload.selectedLegalEntityList.length) || !this.payload.selectedEntiyDocumentList.length) {
                        this.setState(
                            {
                                isLegalEntityError: true
                            })
                        this.sendErrorObjectToOtherComponent();
                    } else {
                        if (!this.props.createView) {
                            this.updateEntitlementApiCall();
                        } else {
                            this.props.history.push('/ManageUser/profile-review');
                        }
                    }
                } else if (this.state.isDealSelected) {
                    if ((!this.state.isAllDealsChecked && !this.payload.selectedDealEntityList.length) || !this.payload.selectedDealDocumentList.length) {
                        this.setState(
                            {
                                isDealError: true
                            })
                        this.sendErrorObjectToOtherComponent();
                    } else {
                        if (!this.props.createView) {
                            this.updateEntitlementApiCall();
                        } else {
                            this.props.history.push('/ManageUser/profile-review');
                        }
                    }
                } else if (this.state.isAssignToAllSelected) {
                    if (!this.props.createView) {
                        this.updateEntitlementApiCall();
                    } else {
                        this.props.history.push('/ManageUser/profile-review');
                    }
                }
            }
        } else {
            this.isTopLevelProfileError = true;
            if (this.props.createView) {
                this.setState({
                    isTopLevelError: true
                });
            } else {
                this.sendErrorObjectToOtherComponent();
            }
        }
        transmitIframeMessage.scrollUp();
    }


    sendErrorObjectToOtherComponent = () => {
        if (!this.props.createView) {
            setTimeout(() => {
                let obj = {
                    isTopLevelError: this.isTopLevelProfileError,
                    isLegalEntitySelected: this.state.isLegalEntitySelected,
                    isDealSelected: this.state.isDealSelected,
                    isAssignToAllSelected: this.state.isAssignToAllSelected,
                    isDealAllSelected: this.state.isAllDealsChecked,
                    isCategoryAllSelected: this.state.isAllDealDocumentsChecked || this.state.isAllLegalDocumentsChecked,
                    isLegalAllSelected: this.state.isAllLegalChecked,
                    isLegalEntityError: this.state.isLegalEntityError,
                    isDealError: this.state.isDealError,
                    isLegalEntityFieldError: this.state.isLegalEntityFieldError,
                    isLegalEntityDocFieldError: this.state.isLegalEntityDocFieldError,
                    isDealFieldError: this.state.isDealFieldError,
                    isDealDocFieldError: this.state.isDealDocFieldError,
                    isPermissionUpdateSuccess: this.isPermissionUpdateSuccess,
                    isPermissionUpdateFailed: this.isPermissionUpdateFailed
                };
                this.submitToHnadeler(obj);
            }, 100);
        }
    }

    submitToHnadeler = (obj) => {

        this.props.onSubmitHandler(obj);
    }

    radioBtnChange = (e) => {
        this.props.onModified && this.props.onModified(true);
        let currentParentEin;
        let initialProfileDataParentEIN;
        try {
            currentParentEin = this.props.initialProfileData.parentEIN !== 'Not Listed' ? this.props.initialProfileData.parentEIN : this.props.currentUser?.parentCompanyInfo?.number
            initialProfileDataParentEIN = this.props.initialProfileData.parentEIN;
        } catch (e) {
            currentParentEin = "";
            initialProfileDataParentEIN = "";
        }
        if (currentParentEin === undefined && initialProfileDataParentEIN === 'Not Listed') { }
        else {
            this.setState({
                legalList: this.state.legalList.map(ls => {
                    ls.selected = false;
                    return ls;
                }),
                legalEntityDocList: this.state.legalEntityDocList.map(ls => {
                    ls.selected = false;
                    return ls
                }),
                dealsList: this.state.dealsList.map(ls => {
                    ls.selected = false;
                    return ls;
                }),
                dealDocumentList: this.state.dealDocumentList.map(ls => {
                    ls.selected = false;
                    return ls
                }),
                isAllDealsChecked: false,
                isAllDealDocumentsChecked: false,
                isAllLegalChecked: false,
                isAllLegalDocumentsChecked: false,
            })
        }
        this.isPermissionUpdateSuccess = false;
        if (e.target.value === 'Legalentity') {
            this.isTopLevelProfileError = false;
            this.setState(
                {
                    isLegalEntitySelected: true,
                    isDealSelected: false,
                    isAssignToAllSelected: false,
                    isTopLevelError: false,
                    isDealError: false,

                }
            )
        } else if (e.target.value === 'Deal') {
            this.isTopLevelProfileError = false;
            this.setState(
                {
                    isLegalEntitySelected: false,
                    isDealSelected: true,
                    isAssignToAllSelected: false,
                    isTopLevelError: false,
                    isLegalEntityError: false,
                }
            )
        } else if (e.target.value === 'Assigntoall') {
            this.isTopLevelProfileError = false;
            this.setState(
                {
                    isLegalEntitySelected: false,
                    isDealSelected: false,
                    isAssignToAllSelected: true,
                    isTopLevelError: false,
                    isLegalEntityError: false,
                    isDealError: false,

                }
            )
        }
        this.setState({
            search: {
                legalEntities: '',
                dealEntities: ''
            }
        })

        this.sendErrorObjectToOtherComponent();
    }

    checkAssigntoallForUpdateUser(responseArr) {//  /// SKK FOO BAR
        if (this.props.userDetails.usbRelationship == getConstants('ROLE_Company_Contributor')) {
            if (responseArr.allCategoriesFlag?.localeCompare('true') == 0
                && responseArr.allDealsFlag?.localeCompare('true') == 0 &&
                responseArr.allReportingEntitysFlag?.localeCompare('true') == 0) {
                return true;
            }
        }
        return responseArr?.reportingEntitys && responseArr?.reportingEntitys?.length > 0 && responseArr?.deals && responseArr?.deals.length > 0
    }

    resetReduxServerResponse() {
        this.props.dispatch({
            type: 'SET_ENTITLEMENT_SERVER_RESPONSE',
            payload: null
        })
    }

    componentDidMount() {
        // this.resetReduxServerResponse();
        let allPermissionURL = null;
        let currentAsignedUrl = null;
        let headerOptions;
        if (this.props.location && (this.props.location.state && this.props.location.state.profileId) || (this.props.initialProfileData && this.props.initialProfileData.profileID)) {
            const currentParentEin = this.props.initialProfileData.parentEIN !== 'Not Listed' ? this.props.initialProfileData.parentEIN : this.props.currentUser?.parentCompanyInfo?.number
            if (this.props.initialProfileData.parentEIN.includes('FRQ_')) {
                allPermissionURL = getDocumentmgmtServiceURL({
                    service: 'getProfilePermissionsforParentEIN',
                    userid: `${this.props.initialProfileData.parentEIN.replace('FRQ_', '')}`
                });
                currentAsignedUrl = getDocumentmgmtServiceURL({
                    service: 'getProfilePermissionsforParentEIN',
                    userid: `${this.props.initialProfileData.userID.replace('ASSOCIATED_EIN_', '')}`
                });
                headerOptions =  {
                    cdcType: "docmanagement"
                }
            } else {
                currentAsignedUrl = getDocumentmgmtServiceURLuser({
                    service: 'getProfilePermissionsforUser',
                    userid: `${this.props.initialProfileData.userID}`
                });
                currentAsignedUrl = currentAsignedUrl + "?role=" + (this.props.userDetails?.profileRole || this.props.initialProfileData?.profileRole);
                headerOptions =  {
                    cdcType: "docmanagement",
                    headers: {
                        // 'jwt': `${sessionStorage.getItem('tok')}`,
                        'user-id': `${this.props.initialProfileData.userID}`
                    }
                }
                if (this.props.initialProfileData.parentEIN === 'Not Listed' && this.props.parentCompanySelection !== undefined) {
                    allPermissionURL = getDocumentmgmtServiceURL({
                        service: 'getProfilePermissionsforParentEIN',
                        userid: this.props.parentCompanySelection
                    });
                } else {

                    allPermissionURL = getDocumentmgmtServiceURL({
                        service: 'getProfilePermissionsforParentEIN',
                        userid: currentParentEin
                    });
                }

            }

        } else {
            const parentEIN = this.getParentCompanyNumber();
            allPermissionURL = getDocumentmgmtServiceURL({
                service: 'getProfilePermissionsforParentEIN',
                userid: `${parentEIN}`
            });
        }
        if (allPermissionURL && currentAsignedUrl) {
            this.resetReduxServerResponse();
            // const config = {
            //     cdcType: "docmanagement",
            //     headers:{
            //         'jwt':`${sessionStorage.getItem('tok')}`,
            //         'user-id':`${sessionStorage.getItem('assignedUser')}`
            //     }
            // }
            LoadingHelper.inFlight();
            Axios.all([
                Axios.get(allPermissionURL, {
                    cdcType: "docmanagement"
                }),
                Axios.get(currentAsignedUrl,headerOptions)
            ]).then(responseArr => {
                LoadingHelper.outFlight();
                //SKK
                //TODO://THIS AssignPermissionHelper needs to be fixed..
                //if (AssignPermissionHelper.isFrequentVendor(this.props.isFrequentVendor, this.props.parentCompany, this.props.initialProfileData)) {
                if (this.props.initialProfileData.parentEIN.includes('FRQ_')) {
                    this.props.dispatch({
                        type: 'SET_ENTITLEMENT_SERVER_RESPONSE',
                        payload: responseArr[0].data
                    })
                    //TODO://REpEAING Code needs to be moved
                    const entitlementForCurrentEin = responseArr[0].data.find(item => item.number === this.props.initialProfileData.parentEIN.replace('FRQ_', ''));
                    let assignedEntitlmentsForCurrentEin = [];
                    try {
                        assignedEntitlmentsForCurrentEin = responseArr[1].data.find(item => item.number === this.props.initialProfileData.parentEIN.replace('FRQ_', ''));
                    } catch (e) { }

                    if (assignedEntitlmentsForCurrentEin === undefined) {
                        assignedEntitlmentsForCurrentEin = [];

                    }
                    this.setUpdateStateForNonFrequentVendor({ ...entitlementForCurrentEin }, assignedEntitlmentsForCurrentEin)
                } else if (AssignPermissionHelper.isFrequentVendor(this.props.isFrequentVendor, this.props.parentCompany, this.props.initialProfileData)) {
                    this.props.dispatch({
                        type: 'SET_ENTITLEMENT_SERVER_RESPONSE',
                        payload: responseArr[0].data
                    })
                    this.setUpdateStateForFrequentVendor(responseArr[0].data, responseArr[1].data);
                }
                else {
                    const currentParentEin = this.props.initialProfileData.parentEIN !== 'Not Listed' ? this.props.initialProfileData.parentEIN : this.props.currentUser?.parentCompanyInfo?.number

                    if (this.props.initialProfileData.parentEIN === 'Not Listed' && currentParentEin === undefined) {

                        let pSelect = this.props.parentCompanySelection; //skk
                        if (pSelect !== undefined) { // THIS means CDC Employee selects the UNlisted vendor from the Manage User List
                            const entitlementForCurrentEinCompany = responseArr[0].data.find(item => item.number === pSelect);
                            let selectedEntilmentForCompany = responseArr[1].data.find(item => item.number === pSelect);
                            this.resetReduxServerResponse();
                            this.state.unlistedVendorSaveFromManageUserList = true;
                            this.props.dispatch({
                                type: 'SET_ENTITLEMENT_SERVER_RESPONSE',
                                payload: entitlementForCurrentEinCompany
                            });
                            this.props.dispatch({
                                type: "SET_PARENT_COMPANY_SELECTION_MANAGE_USER",
                                payload: undefined
                            })
                            if (selectedEntilmentForCompany === undefined) {
                                selectedEntilmentForCompany = [];
                            }
                            this.setUpdateStateForNonFrequentVendor({ ...entitlementForCurrentEinCompany }, selectedEntilmentForCompany);

                        } else { // THIS means CDC Employee selects the UNlisted vendor from Top right search box
                            this.resetReduxServerResponse();
                            this.state.unlistedVendorSaveFromManageUserList = false;
                            this.props.dispatch({
                                type: 'SET_ENTITLEMENT_SERVER_RESPONSE',
                                payload: responseArr[1].data
                            });
                            this.setEntitlmentStateForNonListedVendor(responseArr[1].data);


                        }

                    }
                    else {
                        const entitlementForCurrentEin = responseArr[0].data.find(item => item.number === currentParentEin);

                        let assignedEntitlmentsForCurrentEin = responseArr[1].data.find(item => item.number === currentParentEin);
                        if (this.props.initialProfileData.parentEIN === 'Not Listed' && assignedEntitlmentsForCurrentEin === undefined) {
                            assignedEntitlmentsForCurrentEin = responseArr[1].data.find(item => item.number === this.props.initialProfileData.parentEIN)
                        }
                        if (assignedEntitlmentsForCurrentEin === undefined) {
                            assignedEntitlmentsForCurrentEin = [];
                        }
                        this.props.dispatch({
                            type: 'SET_ENTITLEMENT_SERVER_RESPONSE',
                            payload: entitlementForCurrentEin
                        })
                        this.setUpdateStateForNonFrequentVendor({ ...entitlementForCurrentEin }, assignedEntitlmentsForCurrentEin);
                    }
                }

            }).catch((error) => {
                LoadingHelper.outFlight();
                console.log(error)
                console.error("There is an Error while loading entilements for a User.");
                console.log("There is an Error while loading entilements for a User.");
                this.setState({
                    isApiCallError: true,
                    // loading : false
                });
                transmitIframeMessage.scrollUp();
            });
        } else {

            this.resetReduxServerResponse();

            LoadingHelper.inFlight();
            Axios.get(allPermissionURL, { cdcType: "docmanagement" }).then(response => {
                LoadingHelper.outFlight();
                if (AssignPermissionHelper.isFrequentVendor(this.props.isFrequentVendor, this.props.parentCompany, this.props.initialProfileData)) {
                    this.props.dispatch({
                        type: 'SET_ENTITLEMENT_SERVER_RESPONSE',
                        payload: response.data
                    })
                    this.setEntitlmentStateForFrequentVendor(response.data)
                } else {
                    this.props.dispatch({
                        type: 'SET_ENTITLEMENT_SERVER_RESPONSE',
                        payload: response.data[0]
                    })
                    this.setEntitlmentStateNonVendor(response.data[0]);
                }
                console.log("Permission for new user:", response);

            }).catch((error) => {
                LoadingHelper.outFlight();
                this.setState({
                    isApiCallError: true
                })
                console.error("There is an Permission Error for New User.");
                transmitIframeMessage.scrollUp();
            });
        }
    }

    setUpdateStateForFrequentVendor(allEntitlments, assignedEntitlements) {
        let entitlements = {}
        // check to see which type of entitlements which is already assigned.
        const selectedEntitlement = AssignPermissionHelper.getSelectedEntitlmentForFrequentVendor(assignedEntitlements);
        entitlements = AssignPermissionHelper.buildAllEntitlementListForFrequentVendor('edit', allEntitlments, assignedEntitlements);
        this.setState({
            loading: false,
            isLegalEntitySelected: selectedEntitlement === 'legal' ? true : false,
            isDealSelected: selectedEntitlement === 'deal' ? true : false,
            isAssignToAllSelected: selectedEntitlement === 'assignAll' ? true : false,
            legalList: entitlements.legalList ? entitlements.legalList : [],
            dealsList: entitlements.dealsList ? entitlements.dealsList : [],
            dealDocumentList: entitlements.dealDocumentList ? entitlements.dealDocumentList : [],
            legalEntityDocList: entitlements.legalDocumentList ? entitlements.legalDocumentList : [],

            isAllLegalChecked: entitlements.legalList && entitlements.legalList.length > 0 ? !entitlements.legalList.some(item => item.selected === false) : false,
            isAllDealsChecked: entitlements.dealsList && entitlements.dealsList.length > 0 ? !entitlements.dealsList.some(item => item.selected === false) : false,
            isAllLegalDocumentsChecked: entitlements.legalDocumentList && entitlements.legalDocumentList.length > 0 ? !entitlements.legalDocumentList.some(item => item.selected === false) : false,
            isAllDealDocumentsChecked: entitlements.dealDocumentList && entitlements.dealDocumentList.length > 0 ? !entitlements.dealDocumentList.some(item => item.selected === false) : false,
        })
    }
    extractCategories(category){
        if((category.key+"").includes("-")){
            let tempCategory = (category.key + "").split(" - ")
            return { key : tempCategory[0], value : tempCategory[1]}
        } else {
            return {...category}
        }
    }
    setUpdateStateForNonFrequentVendor(allEntitlments, assignedEntitlements) {
        const allEntitlmentsFirstItem = allEntitlments;
        const isFromServer = this.props.entitlement ? false : true;

        const assignedLegalList = isFromServer ? assignedEntitlements.reportingEntitys : this.props.entitlement.selectedLegalEntityList;
        const assignedDealList = isFromServer ? assignedEntitlements.deals : this.props.entitlement.selectedDealEntityList;
        const assignedLegalDocumentsList = isFromServer ? assignedEntitlements.categories : this.props.entitlement.selectedEntiyDocumentList;
        const assignedDealsDocumentsList = isFromServer ? assignedEntitlements.categories : this.props.entitlement.selectedDealDocumentList;

        const isAssignAll = this.props.isAssignAll !== undefined ? this.props.isAssignAll : this.checkAssigntoallForUpdateUser(assignedEntitlements);
        const isLegalSelected = this.props.isLegalSelected !== undefined ? this.props.isLegalSelected : !isAssignAll && assignedEntitlements.reportingEntitys && assignedEntitlements.reportingEntitys.length > 0 ? true : false;
        const isDealSelected = this.props.isDealSelected !== undefined ? this.props.isDealSelected : !isAssignAll && assignedEntitlements.deals && assignedEntitlements.deals.length > 0 ? true : false;
        const legalList = allEntitlmentsFirstItem.reportingEntitys ? allEntitlmentsFirstItem.reportingEntitys.map(re => {
            const entity = { ...re };
            entity.selected = isLegalSelected ? AssignPermissionHelper.isAlreadySelected(entity, assignedLegalList) : false;
            return entity;
        }) : [];
        let isAllLegalSelected = legalList && legalList.length > 0 ? !legalList.some(item => item.selected === false) : false;
        const dealsList = allEntitlmentsFirstItem.deals ? allEntitlmentsFirstItem.deals.map(re => {
            const entity = { ...re };
            entity.selected = isDealSelected ? AssignPermissionHelper.isAlreadySelected(entity, assignedDealList) : false;
            return entity;
        }) : []
        let isAllDealSelected = dealsList && dealsList.length > 0 ? !dealsList.some(item => item.selected === false) : false;

        const legalDocumentList = allEntitlmentsFirstItem.categories ? allEntitlmentsFirstItem.categories.map(re => {
            const entity = { ...re };
            entity.selected = isLegalSelected ? AssignPermissionHelper.isAlreadySelected(entity, assignedLegalDocumentsList) : false;
            return entity;
        }) : []
        let isAllLegalDocumentsSelected = legalDocumentList && legalDocumentList.length > 0 ? !legalDocumentList.some(item => item.selected === false) : false;

        const dealDocumentList = allEntitlmentsFirstItem.categories ? allEntitlmentsFirstItem.categories.map(re => {
            const entity = {...re};

            entity.selected = isDealSelected ? AssignPermissionHelper.isAlreadySelected(entity, assignedDealsDocumentsList) : false;
            return entity
        }) : []
        let isAllDealDocumentsSelected = dealDocumentList && dealDocumentList.length > 0 ? !dealDocumentList.some(item => item.selected === false) : false;

        this.setState({
            loading: false,
            legalList: legalList,
            dealsList: dealsList,
            dealDocumentList: dealDocumentList,
            legalEntityDocList: legalDocumentList,
            isAllLegalChecked: isAllLegalSelected,
            isAllDealsChecked: isAllDealSelected,
            isAllLegalDocumentsChecked: isAllLegalDocumentsSelected,
            isAllDealDocumentsChecked: isAllDealDocumentsSelected,
            isLegalEntitySelected: isLegalSelected,
            isDealSelected: isDealSelected,
            isAssignToAllSelected: isAssignAll

        })

    }
    setEntitlmentStateForFrequentVendor(data) {
        let entitlements = {}
        if (data) {
            entitlements = AssignPermissionHelper.buildAllEntitlementListForFrequentVendor('create', data, null, this.props.entitlement);
        }
        this.setState({
            loading: false,
            legalList: entitlements.legalList ? entitlements.legalList : [],
            dealsList: entitlements.dealsList ? entitlements.dealsList : [],
            dealDocumentList: entitlements.dealDocumentList ? entitlements.dealDocumentList : [],
            legalEntityDocList: entitlements.legalDocumentList ? entitlements.legalDocumentList : [],
            isLegalEntitySelected: this.props.isLegalSelected,
            isDealSelected: this.props.isDealSelected,
            isAssignToAllSelected: this.props.isAssignAll,

            isAllLegalChecked: entitlements.legalList && entitlements.legalList.length > 0 ? !entitlements.legalList.some(item => item.selected === false) : false,
            isAllDealsChecked: entitlements.dealsList && entitlements.dealsList.length > 0 ? !entitlements.dealsList.some(item => item.selected === false) : false,
            isAllLegalDocumentsChecked: entitlements.legalDocumentList && entitlements.legalDocumentList.length > 0 ? !entitlements.legalDocumentList.some(item => item.selected === false) : false,
            isAllDealDocumentsChecked: entitlements.dealDocumentList && entitlements.dealDocumentList.length > 0 ? !entitlements.dealDocumentList.some(item => item.selected === false) : false,
        })
    }

    setEntitlmentStateForNonListedVendor(data) {
        let entitlements = {}
        if (data) {
            entitlements = AssignPermissionHelper.buildAllEntitlementListForUnListedVendor('create', data, null, this.props.entitlement);
        }
        this.setState({
            loading: false,
            legalList: entitlements.legalList ? entitlements.legalList : [],
            dealsList: entitlements.dealsList ? entitlements.dealsList : [],
            dealDocumentList: entitlements.dealDocumentList ? entitlements.dealDocumentList : [],
            legalEntityDocList: entitlements.legalDocumentList ? entitlements.legalDocumentList : [],
            isLegalEntitySelected: false,
            isDealSelected: true,
            isAssignToAllSelected: false,

            isAllLegalChecked: true,
            isAllDealsChecked: true,
            isAllLegalDocumentsChecked: true,
            isAllDealDocumentsChecked: true,
        })
    }


    /**
     * 
     * Set the entitlement list in the state for non Vendor.
     * @param {} data - response object from api call. 
     */
    setEntitlmentStateNonVendor(data) {
        const legalList = data.reportingEntitys?.map(re => {
            const entity = { ...re };
            entity.selected = this.props.isLegalSelected ? AssignPermissionHelper.isAlreadySelected(entity, this.props.entitlement.selectedLegalEntityList) : false;
            return entity;
        });
        let isAllLegalSelected = legalList && legalList.length > 0 ? !legalList.some(item => item.selected === false) : false;
        const dealsList = data.deals?.map(re => {
            const entity = { ...re };
            entity.selected = this.props.isDealSelected ? AssignPermissionHelper.isAlreadySelected(entity, this.props.entitlement.selectedDealEntityList) : false;
            return entity;
        });
        let isAllDealSelected = dealsList && dealsList.length > 0 ? !dealsList.some(item => item.selected === false) : false;

        const legalDocumentList = data.categories?.map(re => {
            const entity = { ...re };
            entity.selected = this.props.isLegalSelected ? AssignPermissionHelper.isAlreadySelected(entity, this.props.entitlement.selectedEntiyDocumentList) : false;
            return entity
        });
        let isAllLegalDocumentsSelected = legalDocumentList && legalDocumentList.length > 0 ? !legalDocumentList.some(item => item.selected === false) : false;

        const dealDocumentList = data.categories?.map(re => {
            const entity = { ...re };
            entity.selected = this.props.isDealSelected ? AssignPermissionHelper.isAlreadySelected(entity, this.props.entitlement.selectedDealDocumentList) : false;
            return entity;
        });
        let isAllDealDocumentsSelected = dealDocumentList && dealDocumentList.length > 0 ? !dealDocumentList.some(item => item.selected === false) : false;
        this.setState({
            loading: false,
            legalList: legalList ? legalList : [],
            dealsList: dealsList ? dealsList : [],
            dealDocumentList: dealDocumentList,
            legalEntityDocList: legalDocumentList,
            isAllLegalChecked: isAllLegalSelected,
            isAllDealsChecked: isAllDealSelected,
            isAllLegalDocumentsChecked: isAllLegalDocumentsSelected,
            isAllDealDocumentsChecked: isAllDealDocumentsSelected,
            isLegalEntitySelected: this.props.isLegalSelected,
            isDealSelected: this.props.isDealSelected,
            isAssignToAllSelected: this.props.isAssignAll

        })
    }



    getParentCompanyNumber() {
        if (this.props.parentCompany) {
            return this.props.parentCompany.ein;
        }
    }
    handleSelectAll = (e) => {
        this.isPermissionUpdateSuccess = false;
        let chkId = e.target.id;
        const targetFlag = e.target.checked;

        if (chkId === 'allEntity') {
            this.setState({
                legalList: this.state.legalList.map(ls => {
                    ls.selected = targetFlag;
                    return ls;
                }),
                isAllLegalChecked: targetFlag,
                isLegalEntityFieldError: targetFlag ? true : false
            })
        }
        if (chkId === 'allDeals') {
            this.setState({
                dealsList: this.state.dealsList.map(ls => {
                    ls.selected = targetFlag;
                    return ls
                }),
                isAllDealsChecked: targetFlag,
                isDealFieldError: targetFlag ? true : false
            })
        }
        if (chkId === 'entityDocAll') {
            this.setState({
                legalEntityDocList: this.state.legalEntityDocList.map(ls => {
                    ls.selected = targetFlag;
                    return ls
                }),
                isAllLegalDocumentsChecked: targetFlag,
                isLegalEntityDocFieldError: targetFlag ? true : false
            })
        }
        if (chkId === 'dealDocAll') {
            this.setState({
                dealDocumentList: this.state.dealDocumentList.map(ls => {
                    ls.selected = targetFlag;
                    return ls
                }),
                isAllDealDocumentsChecked: targetFlag,
                isDealDocFieldError: targetFlag ? true : false

            })
        }

        this.sendErrorObjectToOtherComponent();
        this.props.onModified && this.props.onModified(true);
    }

    handleCheckBoxChange = (e, type, item) => {
        this.isPermissionUpdateSuccess = false;
        let legalList = this.state.legalList;
        let dealsList = this.state.dealsList;
        let legalEntityDocList = this.state.legalEntityDocList;
        let dealDocumentList = this.state.dealDocumentList;
        let legalAllSelected = this.state.isAllLegalChecked;
        let dealAllSelected = this.state.isAllDealsChecked;
        let documentAllSelected = this.state.isAllDealDocumentsChecked;
        let documentEntityAllSelected = this.state.isAllLegalDocumentsChecked;
        if (type === 'LegalEntity') {
            legalList = this.state.legalList.map(ll => {
                if (ll.key === item.key && ll.value == item.value) {
                    ll.selected = e.target.checked ? true : false
                }
                return ll
            });
            legalAllSelected = legalList.every(row => row.selected);

        }
        if (type === 'DealsEntity') {
            dealsList = this.state.dealsList.map(dl => {
                if (dl.key === item.key && dl.value == item.value) {
                    dl.selected = e.target.checked ? true : false
                }
                return dl
            });
            dealAllSelected = dealsList.every(row => row.selected);
        }
        if (type === 'LegalDocuments') {
            legalEntityDocList = this.state.legalEntityDocList.map(dl => {
                if (dl.key === item.key && dl.value == item.value) {
                    dl.selected = e.target.checked ? true : false
                }
                return dl
            });
            documentEntityAllSelected = legalEntityDocList.every(row => row.selected)
        }
        if (type === 'DealDocuments') {
            dealDocumentList = this.state.dealDocumentList.map(dl => {
                if (dl.key === item.key && dl.value == item.value) {
                    dl.selected = e.target.checked ? true : false
                }
                return dl
            });
            documentAllSelected = dealDocumentList.every(row => row.selected)
        }
        this.setState({
            dealDocumentList: dealDocumentList,
            dealsList: dealsList,
            legalList: legalList,
            isAllDealsChecked: dealAllSelected,
            isAllLegalChecked: legalAllSelected,
            isAllDealDocumentsChecked: documentAllSelected,
            isAllLegalDocumentsChecked: documentEntityAllSelected,
            isLegalEntityFieldError: legalList?.filter(ll => ll.selected).length ? true : false,
            isLegalEntityDocFieldError: legalEntityDocList?.filter(ll => ll.selected).length ? true : false,
            isDealFieldError: dealsList?.filter(ll => ll.selected).length ? true : false,
            isDealDocFieldError: dealDocumentList?.filter(ll => ll.selected).length ? true : false

        })
        this.props.onModified && this.props.onModified(true);

        this.sendErrorObjectToOtherComponent();

    }

    getBackNavigationUrl = () => {
        if (this.props.currentUser.custom_data.role.toLowerCase().includes(UserRoleConfig.superAdmin)) {
            if (this.props.createView && this.props.parentCompany.businessProfileType.toLowerCase() === "customer") {
                return '/ManageUser/user-type';

            } else {
                return '/ManageUser/emp-reg';
            }
        } else if (this.props.currentUser.custom_data.role.toLowerCase().includes(UserRoleConfig.customer)) {
            if (this.props.userType && this.props.userType.type === "Vendor") {
                return '/ManageUser/user-type';
            } else {
                return '/ManageUser/emp-reg';
            }
        }
    }

    addCategories = (item) => {
        if((item.key + "").includes("-") ){
            return { key: item.key, value: item.key }
        } else {
            if(item.key == item.value || item.value==""){
                return { key: item.key, value: item.key }
            }
            return { key: item.key + " - " + item.value, value: item.key + " - " + item.value }
        }
    }

    updateEntitlementApiCall = async () => {
        this.isPermissionUpdateSuccess = false;
        this.isPermissionUpdateFailed = false;
        let entitlementsUpdate = {
            url: '',
            body: null
        };
        const res = await entitlementUpdateThunk(this.payload, {
            isLegalEntitySelected: this.state.isLegalEntitySelected,
            isDealSelected: this.state.isDealSelected,
            isAssignToAllSelected: this.state.isAssignToAllSelected
        }, {
            isDealAllSelected: this.state.isAllDealsChecked,
            isLegalAllSelected: this.state.isAllLegalChecked,
            isCategoryAllSelected: this.state.isAllDealDocumentsChecked || this.state.isAllLegalDocumentsChecked
        });
        this.getEntitlement = new EntitlementDetails();


        entitlementsUpdate.url = getDocumentmgmtServiceURL({
            service: 'updateProfilePermission',
            userid: `${this.props.initialProfileData.userID}`
        });

        //SKK //overwriting it to avoid any possible side effects
        if (this.props.initialProfileData.parentEIN.includes('FRQ_') ||
            this.props.initialProfileData.parentEIN.includes('Not Listed')) {

            entitlementsUpdate.url = getDocumentmgmtServiceURL({
                service: 'vendor_association_and_deassociation',
                userid: `${this.props.initialProfileData.userID}`
            });
        }

        let entitlement = {};
        let serverEntitlements;
        if (AssignPermissionHelper.isFrequentVendor(this.props.isFrequentVendor, this.props.parentCompany, this.props.initialProfileData)) {
            entitlement = this.getEntitlement.getVendorEntitlementBodyForApi();
            serverEntitlements = this.props.entitlementServerData;
        } else {
            if (this.state.isLegalEntitySelected) {
                entitlement.reportingEntitys = this.state.legalList.filter(ll => ll.selected).map(item => ({ key: item.key, value: item.value }));
                entitlement.categories = this.state.legalEntityDocList.filter(ll => ll.selected).map(this.addCategories)
            } else if (this.state.isDealSelected) {
                entitlement.deals = this.state.dealsList.filter(ll => ll.selected).map(item => ({ key: item.key, value: item.value }));
                entitlement.categories = this.state.dealDocumentList.filter(ll => ll.selected).map(this.addCategories);
            }
            if (this.props.initialProfileData?.usbRelationship === 'Unlisted_Vendor_Contributor__Contact') {
                if (this.props.currentUser.pid !== 'employee_signon' && !this.state.unlistedVendorSaveFromManageUserList) {
                    entitlement.name = this.props.currentUser.parentCompanyInfo.parentCompanyName
                    entitlement.number = this.props.currentUser.parentCompanyInfo.number
                    entitlement.parentLegalEntityID = this.props.currentUser.parentCompanyInfo.parentCompanyLegalEntityID
                } else if (this.props.currentUser.pid === 'employee_signon' && this.state.unlistedVendorSaveFromManageUserList) {
                    let svcEntlDta = this.props.entitlementServerData;

                    entitlement.name = svcEntlDta.name
                    entitlement.number = svcEntlDta.number
                    entitlement.parentLegalEntityID = svcEntlDta.parentLegalEntityID


                }
            } else {
                entitlement.name = this.props.initialProfileData.parentCompanyName;
                entitlement.number = this.props.initialProfileData.parentEIN.replace('FRQ_', '')
                entitlement.parentLegalEntityID = this.props.initialProfileData.parentCompanyLegalEntityID
            }
            entitlement.allDealsFlag = this.state.isAllDealsChecked ? "true" : "false";
            entitlement.allReportingEntitysFlag = this.state.isAllLegalChecked ? "true" : "false";
            entitlement.allCategoriesFlag = this.state.isAllDealDocumentsChecked || this.state.isAllLegalDocumentsChecked ? "true" : "false";
            entitlement.role = this.props.initialProfileData.profileRole
            entitlement = [entitlement];
            serverEntitlements = [this.props.entitlementServerData];
        }

        try {
            if (serverEntitlements.length === entitlement.length) {
                if (serverEntitlements[0].number === entitlement[0].number) {
                    serverEntitlements[0].role = entitlement[0].role;
                }
            }
        } catch (error) {

        }

        //SKK
        if (this.props.initialProfileData.parentEIN.includes('FRQ_')) {
            entitlementsUpdate.body = {
                "vendorNumber": this.props.initialProfileData.userID.replace('ASSOCIATED_EIN_', ''),

                "source": "CDC",
                "activation": true,
                "permissions": [
                    {
                        "name": "CDC",
                        "parentEntitys": this.state.isAssignToAllSelected ? serverEntitlements.map(ent => {
                            return {
                                ...ent[0],
                                allDealsFlag: "true",
                                allReportingEntitysFlag: "true",
                                allCategoriesFlag: "true"
                            }
                        }) : entitlement
                    }
                ]
            }
        } else if (this.props.initialProfileData.parentEIN.includes('Not Listed')) {

            let currentLogginginUser = this.props.currentUser;
            let jabaserverEntitlements = this.props.entitlementServerData;
            if (currentLogginginUser.pid !== 'employee_signon') {
                //SKKSKKSKK
                let assingAllStatus = this.props.isAssignAll;
                if (assingAllStatus === null || assingAllStatus === undefined) {
                    assingAllStatus = false;
                }
                if (assingAllStatus) {
                    entitlementsUpdate.body = {
                        "vendorProfileId": this.props.initialProfileData.profileID,

                        "source": "CDC",
                        "activation": true,
                        "permissions": [
                            {
                                "name": "CDC",
                                "parentEntitys": entitlement

                            }
                        ]
                    }
                } else {
                    entitlementsUpdate.body = {
                        "vendorProfileId": this.props.initialProfileData.profileID,

                        "source": "CDC",
                        "activation": true,
                        "permissions": [
                            {
                                "name": "CDC",
                                "parentEntitys": this.state.isAssignToAllSelected ? serverEntitlements.map(ent => {
                                    return {
                                        ...ent[0],
                                        allDealsFlag: "true",
                                        allReportingEntitysFlag: "true",
                                        allCategoriesFlag: "true"
                                    }
                                }) : entitlement
                            }
                        ]
                    }
                }

            } else {
                if (this.state.unlistedVendorSaveFromManageUserList) { // skk
                    entitlementsUpdate.body = {
                        "vendorProfileId": this.props.initialProfileData.profileID,

                        "source": "CDC",
                        "activation": true,
                        "permissions": [
                            {
                                "name": "CDC",
                                "parentEntitys": entitlement

                            }
                        ]
                    }
                } else {
                    entitlementsUpdate.body = {
                        "vendorProfileId": this.props.initialProfileData.profileID,

                        "source": "CDC",
                        "activation": true,
                        "permissions": [
                            {
                                "name": "CDC",
                                "parentEntitys": this.props.entitlementServerData
                            }
                        ]
                    }
                }
            }

        }
        else {
            entitlementsUpdate.body = {
                "profileId": this.props.initialProfileData.profileID,
                "source": "CDC",
                "userId": this.props.initialProfileData.userID,
                "emailId": this.props.initialProfileData.email,
                "firstName": this.props.initialProfileData.firstName,
                "lastName": this.props.initialProfileData.lastName,
                "middleName": this.props.initialProfileData.middleName,
                "mobileNumber": [
                    this.props.initialProfileData.phoneNumbers.mobilePhone,
                    this.props.initialProfileData.phoneNumbers.businessPhone
                ],
                "permissions": [
                    {
                        "name": "CDC",
                        "parentEntitys": this.state.isAssignToAllSelected ? serverEntitlements.map(ent => {
                            return {
                                ...ent,
                                allDealsFlag: "true",
                                allReportingEntitysFlag: "true",
                                allCategoriesFlag: "true"
                            }
                        }) : entitlement
                    }
                ]
            }
        }
        if (AssignPermissionHelper.isFrequentVendor(this.props.isFrequentVendor, this.props.parentCompany, this.props.initialProfileData)) {
            entitlementsUpdate.body.vendorRelationship = [{
                "vendorName": this.props.parentCompany.parentCompanyName,
                "vendorNumber": this.props.parentCompany.ein,
                "vendorRole": this.props.initialProfileData.profileRole,
                "vendorLegalEntityID": this.props.parentCompany.parentCompanyLegalEntityID
            }]
        } 
        
        if (this.state.unlistedVendorSaveFromManageUserList) {

            let resetBody = {
                "vendorProfileId": this.props.initialProfileData.profileID,

                "source": "CDC",
                "activation": false,
                "permissions": [
                    {
                        "name": "CDC",
                        "parentEntitys": serverEntitlements
                    }
                ],


            }


            Axios.put(entitlementsUpdate.url, resetBody, { cdcType: "docmanagement" })
                .then((res) => {
                    Axios.put(entitlementsUpdate.url, entitlementsUpdate.body, { cdcType: "docmanagement" })
                        .then((res) => {
                            LoadingHelper.outFlight();
                            this.isPermissionUpdateSuccess = true;
                            this.sendErrorObjectToOtherComponent();
                            this.props.onModified && this.props.onModified(false);
                        }).catch((error) => {
                            LoadingHelper.outFlight();
                            this.isPermissionUpdateSuccess = false;
                            this.isPermissionUpdateFailed = true;
                            this.sendErrorObjectToOtherComponent();
                        });
                }).catch((error) => {
                    LoadingHelper.outFlight();
                    this.isPermissionUpdateSuccess = false;
                    this.isPermissionUpdateFailed = true;
                    this.sendErrorObjectToOtherComponent();
                });

        } else {
            Axios.put(entitlementsUpdate.url, entitlementsUpdate.body, { cdcType: "docmanagement" })
                .then((res) => {
                    LoadingHelper.outFlight();
                    this.isPermissionUpdateSuccess = true;
                    this.sendErrorObjectToOtherComponent();
                    this.props.onModified && this.props.onModified(false);
                }).catch((error) => {
                    LoadingHelper.outFlight();
                    this.isPermissionUpdateSuccess = false;
                    this.isPermissionUpdateFailed = true;
                    this.sendErrorObjectToOtherComponent();
                });

        }

    }

    siteAdminUser = () => {
        if (this.props.userDetails) {
            return (this.props.userDetails.profileRole.toLowerCase() === 'site administrator')
        }
    }

    vendorSiteAdminUser = () => {
        return (AssignPermissionHelper.isFrequentVendor(this.props.isFrequentVendor, this.props.parentCompany, this.props.initialProfileData))
    }

    customerSiteAdminUser = () => {
        if (this.props.currentUser) {
            return (this.props.currentUser.custom_data.role.toLowerCase().includes(UserRoleConfig.customer) &&
                !this.vendorSiteAdminUser())
        }
    }

    searchInputChange = (name, value) => {
        this.setState({
            loading: true
        }, () => {
            this.setState({
                search: {
                    ...this.state.search,
                    [name]: value
                }
            }, () => {
                this.setState({
                    loading: false
                })
            })
        })

    }

    getOnlyFilteredDisplayList = (checkboxName, itemsList, searchValue) => {
        let filteredList = itemsList && itemsList.length > 0 ? itemsList : [];
        if (searchValue && filteredList && filteredList.length > 0) {
            filteredList = itemsList.filter(item => item.value.toLowerCase().startsWith(searchValue.toLowerCase()));
        }
        if (filteredList && filteredList.length > 0) {
            const legalDisplayList = filteredList.map((ll, index) => {
                return <USBCheckbox checkboxID={ll.key + index}
                    id = {ll.key + ll.value}
                    key={ll.key + '-' + index}
                    initIsChecked={ll.selected}
                    handleChange={e => this.handleCheckBoxChange(e, checkboxName, ll)}
                    labelText={ ll.key?.includes("-") ? ll.key : ll.key != ll.value && (ll.value==null || ll.value==undefined || ll.value!="") ? ll.key  +' - ' + ll.value : ll.key}
                    isError={(this.state.isDealError && !this.state.isDealDocFieldError) ||
                        (this.state.isDealError && !this.state.isDealFieldError) ||
                        (this.state.isLegalEntityError && !this.state.isLegalEntityDocFieldError) ||
                        (this.state.isLegalEntityError && !this.state.isLegalEntityFieldError) ? true : this.state.isTopLevelProfileError}
                    addClasses="sp-mr-b-12" />
            })
            return legalDisplayList;
        }
        return (<div className="sp-caption sp-caption-spacing sp-mr-b-12">{checkboxName === 'LegalEntity' ? <div>There are currently no legal entities. Choose <strong>Select all</strong> to continue. The user will have access to all legal entities when they're added. You'll have the option to assign specific permissions at this time.</div>
            : checkboxName === 'DealsEntity' ? <div>There are currently no deals.  Choose <strong>Select all</strong> to continue.  The user will have access to all deals when they're added.  You'll have the option to assign specific permissions at this time.</div> : null}<br></br></div>)

    }

    isSearchView = (itemsList) => {
        if (itemsList && itemsList.length > this.maxEntityLength) {
            return true;
        }
        return false;
    }

    onJumpLinkClick = () => {
        setTimeout(() => {
            let str = window.location.hash;
            this.handleFieldFocus(str);
        }, 300);

    }


    handleFieldFocus = (type: string): void => {
        switch (type) {
            case "#option":
                document.getElementById('radioOption1').focus();
                break;
            case "#legal-entities":
                document.getElementById('allEntity').focus();
                break;
            case "#entity-documents":
                document.getElementById('entityDocAll').focus();
                break;
            case "#deals":
                document.getElementById('allDeals').focus();
                break;
            case "#deal-documents":
                document.getElementById('dealDocAll').focus();
                break;
            default:
                break;
        }
    }


    resetNotification = () => {
        this.setState({
            isApiCallError: false
        })
    }


    render() {

        const legalEntityRadioProps = {
            [componentConfig.USBRadio.formGroupID]: "radio",
            [componentConfig.USBRadio.labelText]: "Legal entity",
            [componentConfig.USBRadio.stacked]: true,
            [componentConfig.USBRadio.handleChange]: this.radioBtnChange,

        };

        const dealRadioProps = {
            [componentConfig.USBRadio.formGroupID]: "radio",
            [componentConfig.USBRadio.labelText]: "Deal",
            [componentConfig.USBRadio.stacked]: false,
            [componentConfig.USBRadio.handleChange]: this.radioBtnChange,

        };

        const assignToAllRadioProps = {
            [componentConfig.USBRadio.formGroupID]: "radio",
            [componentConfig.USBRadio.labelText]: "Assign to all",
            [componentConfig.USBRadio.stacked]: false,
            [componentConfig.USBRadio.handleChange]: this.radioBtnChange,

        };

        const continueBtnProps = {
            [componentConfig.USBButton.variant]: "primary",
            [componentConfig.USBButton.addClasses]: "sp-button-spacing",
            [componentConfig.USBButton.handleClick]: this.onContinue
        };

        const cancelBtnProps = {
            [componentConfig.USBButton.variant]: "secondary",
            [componentConfig.USBButton.addClasses]: "sp-button-spacing",
            [componentConfig.USBButton.handleClick]: this.onCancelClick
        };


        return (
            <React.Fragment>
                {this.props.createView ? <ProgressIndicator currentStep={3} id='empRegStep' /> : null}
                {this.siteAdminUser() ?
                    <div className="sp-row">
                        {(!this.customerSiteAdminUser() && !this.vendorSiteAdminUser()) || this.vendorSiteAdminUser() ?
                            <div className="sp-col-6">The user has access to all current and future reporting requirements.
                                <br></br>
                                <div className="sp-caption">
                                    To assign specific permissions, go to the <strong>Personal info</strong> tab.  Then select <strong>Contributor</strong> from the <strong>Role</strong> dropdown menu.
                                </div>
                            </div> :
                            (this.customerSiteAdminUser() ?
                                <div className="sp-col-6">The user has access to all current and future reporting requirements for your organization.
                                    <br></br>
                                    <div className="sp-caption">
                                        To assign specific permissions, go to the <strong>Personal info</strong> tab.  Then select <strong>Contributor</strong> from the <strong>Role</strong> dropdown menu.
                                    </div>
                                </div> : '')
                        }
                    </div> :
                    <div className={`sp-container-fluid ${this.props.createView ? 'sp-progress-content-space' : ''}`} id="assign-permission">
                        <div className="sp-row">
                            <div className={`sp-col-6 ${this.props.createView ? 'sp-offset-left-3' : ''}`}>
                                <div className="sp-mr-b-32">
                                    {this.props.createView ?
                                        <>
                                            <NavigateBack url={this.getBackNavigationUrl()} />
                                            <h1 className="sp-page-title sp-mr-b-16">Assign permissions</h1>
                                            {this.state.isApiCallError ?
                                                <USBNotification
                                                    addClasses="sp-width-100 sp-mr-b-16 sp-mr-t-12"
                                                    variant={"error"}
                                                    handleClose={this.resetNotification}
                                                    notificationData={[{
                                                        text: "We’re having trouble accessing information. Please refresh your browser and try again."
                                                    }
                                                    ]}
                                                >
                                                </USBNotification> : null}
                                            {this.state.isTopLevelError ?
                                                <div onClick={this.onJumpLinkClick}>
                                                    <USBNotification
                                                        addClasses="sp-mr-b-12"
                                                        variant="error"
                                                        roleType="alert"
                                                        notificationData={[{
                                                            text: "Select how you want to",
                                                            linkText: 'assign permissions.',
                                                            link: '#option'
                                                        }]}
                                                    />
                                                </div> : null}
                                            {this.state.isLegalEntitySelected && this.state.isLegalEntityError && !this.state.isLegalEntityFieldError && this.state.isLegalEntityDocFieldError ?
                                                <div onClick={this.onJumpLinkClick}>
                                                    <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[{
                                                        text: 'Select your',
                                                        linkText: 'legal entities.',
                                                        link: '#legal-entities'
                                                    }
                                                    ]} /></div> : null}
                                            {this.state.isLegalEntitySelected && this.state.isLegalEntityError && this.state.isLegalEntityFieldError && !this.state.isLegalEntityDocFieldError ?
                                                <div onClick={this.onJumpLinkClick}>
                                                    <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[{
                                                        text: 'Select your',
                                                        linkText: 'document types.',
                                                        link: '#entity-documents'
                                                    }
                                                    ]} /> </div> : null}
                                            {this.state.isLegalEntitySelected && this.state.isLegalEntityError && !this.state.isLegalEntityFieldError && !this.state.isLegalEntityDocFieldError ?
                                                <div onClick={this.onJumpLinkClick}>
                                                    <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[{
                                                        text: 'Select your',
                                                        linkText: 'legal entities.',
                                                        link: '#legal-entities'
                                                    }, {
                                                        text: 'Select your',
                                                        linkText: 'document types.',
                                                        link: '#entity-documents'
                                                    }
                                                    ]} /></div> : null}
                                            {this.state.isDealSelected && this.state.isDealError && !this.state.isDealFieldError && this.state.isDealDocFieldError ?
                                                <div onClick={this.onJumpLinkClick}>
                                                    <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[{
                                                        text: 'Select your',
                                                        linkText: 'deals.',
                                                        link: '#deals'
                                                    }
                                                    ]} /></div> : null}
                                            {this.state.isDealSelected && this.state.isDealError && this.state.isDealFieldError && !this.state.isDealDocFieldError ?
                                                <div onClick={this.onJumpLinkClick}>
                                                    <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[{
                                                        text: 'Select your',
                                                        linkText: 'document types.',
                                                        link: '#deal-documents'
                                                    }
                                                    ]} /></div> : null}
                                            {this.state.isDealSelected && this.state.isDealError && !this.state.isDealFieldError && !this.state.isDealDocFieldError ?
                                                <div onClick={this.onJumpLinkClick}>
                                                    <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[{
                                                        text: 'Select your',
                                                        linkText: 'deals.',
                                                        link: '#deals'
                                                    }, {
                                                        text: 'Select your',
                                                        linkText: 'document types.',
                                                        link: '#deal-documents'
                                                    }
                                                    ]} /></div> : null}

                                            <p className="sp-body-medium sp-mr-b-24">You can give access to reporting requirements by legal entity or deal. If you’d like to provide access to all reporting requirements, choose<strong> Assign to all.</strong>
                                            </p>
                                        </>
                                        : ''
                                    }
                                    <h2 className="sp-mr-b-12">Select how you’d like to assign permissions.</h2>
                                    {!this.state.loading ?
                                        <div className="sp-selection-radio sp-mr-b-8">
                                            {this.state.isTopLevelError ? <div style={{ color: 'rgb(222,22,43)' }}>Choose an option to continue.</div> : null}
                                            <div id="option">
                                                <USBRadio id={'legalEntityRadioBtn'} radioID="radioOption1" isError={this.state.isTopLevelError ? true : this.state.isTopLevelProfileError}
                                                    {...legalEntityRadioProps}
                                                    initIsChecked={this.state.isLegalEntitySelected} />
                                            </div>
                                            <div >
                                                <USBRadio id={'dealRadioBtn'} radioID="radioOption2" isError={this.state.isTopLevelError ? true : this.state.isTopLevelProfileError} {...dealRadioProps} initIsChecked={this.state.isDealSelected} />
                                            </div>
                                            <div>
                                                <USBRadio id={'assignToAllRadioBtn'} radioID="radioOption3" isError={this.state.isTopLevelError ? true : this.state.isTopLevelProfileError}
                                                    {...assignToAllRadioProps}
                                                    initIsChecked={this.state.isAssignToAllSelected} />
                                            </div>
                                        </div> : null
                                    }
                                    <div className="sp-caption sp-caption-spacing sp-mr-b-24">
                                        The user will have access to all current and future reporting requirements.
                                    </div>

                                    {this.state.isLegalEntitySelected ?
                                        <div>
                                            <h3 id="legal-entities" className="sp-body-medium sp-mr-b-12"><strong>Choose your legal entities.</strong></h3>
                                            {this.isSearchView(this.state.legalList) &&
                                                <USBSearchInput
                                                    id="legal-entities-search"
                                                    placeholder="Search"
                                                    name="legalEntities"
                                                    initInputValue={this.state.search.legalEntities}
                                                    handleChange={(e) => this.searchInputChange('legalEntities', e.target.value)}
                                                >
                                                </USBSearchInput>}
                                            <div className={this.isSearchView(this.state.legalList) ? 'select-entities-wrapper ' : ''}>
                                                {this.state.isLegalEntityError && !this.state.isLegalEntityFieldError ? <div style={{ color: 'rgb(222,22,43)' }}>Select all or choose from the list.</div> : null}
                                                <USBCheckbox checkboxID="allEntity"
                                                    handleChange={e => this.handleSelectAll(e)}
                                                    labelText="Select all"
                                                    addClasses="sp-mr-b-8"
                                                    isError={this.state.isLegalEntityError && !this.state.isLegalEntityFieldError ? true : this.state.isTopLevelProfileError}
                                                    initIsChecked={this.state.isAllLegalChecked} />
                                                <div className="sp-caption sp-caption-spacing sp-mr-b-12">{this.state.legalList.length ? 'The user will have access to all current and future legal entities.' : null}</div>
                                                {!this.state.loading ?

                                                    <div >
                                                        {this.getOnlyFilteredDisplayList('LegalEntity', this.state.legalList, this.state.search.legalEntities)}
                                                    </div>
                                                    : ''}
                                            </div>
                                            {this.isSearchView(this.state.legalList) ? <div className="sp-caption sp-mr-b-32">{this.state.legalList.length} legal entities </div> : ''}

                                            <h3 id="entity-documents" className="sp-body-medium sp-mr-b-12"><strong>Choose your documents.</strong></h3>
                                            {this.state.isLegalEntityError && !this.state.isLegalEntityDocFieldError ? <div style={{ color: 'rgb(222,22,43)' }}>Select all or choose from the list.</div> : null}
                                            {this.isSearchView(this.state.legalEntityDocList) &&
                                                <USBSearchInput
                                                    id="legal-entities-document-search"
                                                    placeholder="Search"
                                                    name="legalEntitiesDocument"
                                                    initInputValue={this.state.search.legalDocumentList}
                                                    handleChange={(e) => this.searchInputChange('legalDocumentList', e.target.value)}
                                                >
                                                </USBSearchInput>}
                                            <div className={this.isSearchView(this.state.legalEntityDocList) ? 'select-entities-wrapper ' : ''}>
                                                <USBCheckbox checkboxID="entityDocAll"
                                                    handleChange={e => this.handleSelectAll(e)}
                                                    initIsChecked={this.state.isAllLegalDocumentsChecked}
                                                    isError={this.state.isLegalEntityError && !this.state.isLegalEntityDocFieldError ? true : this.state.isTopLevelProfileError}
                                                    labelText="Select all" />

                                                <div className=" sp-caption sp-caption-spacing sp-mr-b-12">The user will have access to all current and future documents.</div>
                                                {!this.state.loading ?
                                                    <div >
                                                        {this.getOnlyFilteredDisplayList('LegalDocuments', this.state.legalEntityDocList, this.state.search.legalDocumentList)}
                                                    </div> : ''}
                                            </div>
                                            {this.isSearchView(this.state.legalEntityDocList) ? <div className="sp-caption sp-mr-b-32">{this.state.dealDocumentList.length} legal entities </div> : ''}

                                        </div> : null
                                    }

                                    {this.state.isDealSelected ?

                                        <div>

                                            <h3 id="deals" className="sp-body-medium sp-mr-b-12"><strong>Choose your deals.</strong></h3>
                                            {this.isSearchView(this.state.dealsList) &&
                                                <USBSearchInput
                                                    id="deal-entities-search"
                                                    placeholder="Search"
                                                    name="dealEntities"
                                                    initInputValue={this.state.search.dealEntities}
                                                    handleChange={(e) => this.searchInputChange('dealEntities', e.target.value)}
                                                >
                                                </USBSearchInput>}
                                            <div className={this.isSearchView(this.state.dealsList) ? 'select-entities-wrapper' : ''}>
                                                {this.state.isDealError && !this.state.isDealFieldError ? <div style={{ color: 'rgb(222,22,43)' }}>Select all or choose from the list.</div> : null}
                                                <USBCheckbox
                                                    checkboxID="allDeals"
                                                    handleChange={e => this.handleSelectAll(e)}
                                                    initIsChecked={this.state.isAllDealsChecked}
                                                    isError={this.state.isDealError && !this.state.isDealFieldError ? true : this.state.isTopLevelProfileError}
                                                    labelText="Select all" addClasses="sp-mr-b-8" />
                                                <div className="sp-caption sp-caption-spacing sp-mr-b-12">{this.state.dealsList.length ? 'The user will have access to all current and future deals.' : null}</div>
                                                {!this.state.loading ?
                                                    <div >
                                                        {this.getOnlyFilteredDisplayList('DealsEntity', this.state.dealsList, this.state.search.dealEntities)}
                                                    </div> : ''}
                                            </div>
                                            {this.isSearchView(this.state.dealsList) ? <div className="sp-caption sp-mr-b-32">{this.state.dealsList.length} deal entities </div> : ''}


                                            <h3 id="deal-documents" className="sp-body-medium sp-mr-b-12"><strong>Choose the documents you want to provide access to.</strong></h3>
                                            {this.state.isDealError && !this.state.isDealDocFieldError ? <div style={{ color: 'rgb(222,22,43)' }}>Select all or choose from the list.</div> : null}
                                            {this.isSearchView(this.state.dealDocumentList) &&
                                                <USBSearchInput
                                                    id="deal-entities-document-search"
                                                    placeholder="Search"
                                                    name="dealEntityDocumentSearch"
                                                    initInputValue={this.state.search.legalDocumentList}
                                                    handleChange={(e) => this.searchInputChange('dealDocList', e.target.value)}
                                                >
                                                </USBSearchInput>}
                                            <div className={this.isSearchView(this.state.dealDocumentList) ? 'select-entities-wrapper ' : ''}>

                                                <USBCheckbox checkboxID="dealDocAll"
                                                    handleChange={e => this.handleSelectAll(e)}
                                                    initIsChecked={this.state.isAllDealDocumentsChecked}
                                                    isError={this.state.isDealError && !this.state.isDealDocFieldError ? true : this.state.isTopLevelProfileError}
                                                    labelText="Select all" />
                                                <div className="sp-caption sp-caption-spacing sp-mr-b-12">The user will have access to all current and future documents for your organization. </div>
                                                {!this.state.loading ?
                                                    <div >
                                                        {this.getOnlyFilteredDisplayList('DealDocuments', this.state.dealDocumentList, this.state.search.dealDocList)}
                                                    </div> : ''}

                                            </div>
                                            {this.isSearchView(this.state.dealDocumentList) ? <div className="sp-caption sp-mr-b-32">{this.state.dealDocumentList.length} legal entities </div> : ''}

                                        </div> : null
                                    }

                                    <div className="sp-action-button-wrapper">
                                        <USBButton id={'assignPerCancelBtn'} {...cancelBtnProps}>Cancel</USBButton>
                                        <USBButton id={'assignPerContinueBtn'} {...continueBtnProps}>
                                            {this.props.createView ? 'Continue' : 'Save changes'}</USBButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment >
        );
    }

}

const mapStateToProps = state => {
    return {
        initialProfileData: state.userProfileInformation.profileServerData,
        userType: state.userProfileInformation.userType,
        parentCompany: state.parentCompanyInformation.parentCompany,
        parentCompanySelection: state.parentCompanyInformation.parentCompanySelection,
        entitlement: state.entitlement.entitlement,
        entitlementServerData: state.entitlement.entitlementServerResponse,
        currentUser: state.currentUser.user,
        isFrequentVendor: state.currentUser.isFrequentVendor,
        userDetails: state.userProfileInformation.profileData,
        isLegalSelected: state.entitlement.legalEntity,
        isDealSelected: state.entitlement.dealEntity,
        isAssignAll: state.entitlement.assignAll
    };
};

export default withRouter(connect(mapStateToProps)(AssignPermissions));
