import React, { useState, useEffect, useContext, Suspense } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from '../../components/utilities/Loader';
import { USBGrid, USBColumn, USBButton } from '@usb-shield/components-react';
import TopMenu from '../../components/molecules/TopMenu';
import BannerWarning from '../../components/common/banner';
import GlobalHead from '../../components/organisms/GlobalHead';
import GlobalFooter from '../../components/organisms/GlobalFooter';
import logo from '../../assets/images/usbank.png';
import { USBIconPeople, USBIconPerson, USBIconDocument, USBIconMail, USBIconBriefcase, USBIconFolderClosed, USBIconSettings, USBIconEnvelope } from '@usb-shield/components-react/dist/cjs';
import useUserRole from '../../components/Hooks/use-UserRole'
import { PermissionRoleContext } from '../../components/Hooks/usePermissionRoles';
import { FeatureFlagsContext } from '../../components/Hooks/useFeatureFlags';
import { DocContext } from '../../pages/context/DocContext'
import { DashboardContext } from '../../pages/context/DashboardContext'
import { enableClosingBinders } from '../../config/connections';
import { endpoints } from '../../../config/connections';
import callApi from '../../components/utilities/callApi';
import SessionHeader from './SessionHeader';

import './landingPage.css'

const LandingPage = () => {

    const userCheck = useUserRole();
    const history = useHistory();

    const { permissionDetails } = useContext(PermissionRoleContext);
    const { featureDetails } = useContext(FeatureFlagsContext);
    const { userType, servicingUserRole, currentMenu, userName, assignedUserName } = useContext(DocContext);
    const remote_customer = JSON.parse(sessionStorage.getItem('remote_user'))
        ? JSON.parse(sessionStorage.getItem('remote_user')).userID
        : null;
    const [remoteUserPermissions, setRemoteUserPermissions] = useState([])
    const [remoteUserFeatureFlags, setRemoteUserFeatureFlags] = useState([])
    const token =
        typeof sessionStorage !== 'undefined'
            ? sessionStorage.getItem('tok')
            : null;

    const parseJwt = (tok) => {
        const base64Url = tok !== null ? tok.split('.')[1] : '';
        const base64 = decodeURIComponent(
            atob(base64Url)
                .split('')
                .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
                .join('')
        );

        return JSON.parse(base64);
    };

    const [currentToken, setCurrentToken] = useState(token);

    const tiles = [
        { title: 'My Portfolio', icon: <USBIconBriefcase size={64} colorVariant={'brand-primary'} title={'My portfolio'}></USBIconBriefcase>, url: '/ReportingDashboard', visibility: sessionStorage.getItem('remote_user') ? ["portfolio"].some(cb => remoteUserPermissions?.includes(cb)) : ["portfolio"].some(cb => permissionDetails?.includes(cb)) },
        { title: 'Closing Binders', icon: <USBIconFolderClosed size={64} colorVariant={'brand-primary'} title={'Closing Binders'}></USBIconFolderClosed>, url: '/ClosingBinders/Portfolio', visibility: (userCheck === 'employee' && !sessionStorage.getItem('remote_user')) && enableClosingBinders == true && ["cbportfolio", "cbdoctype", "cbfilenettype"].some(cb => permissionDetails?.includes(cb)) },
        { title: 'Inbox', icon: <USBIconEnvelope size={64} colorVariant={'brand-primary'} title={'Inbox'}></USBIconEnvelope>, url: '/Inbox', visibility: sessionStorage.getItem('remote_user') ? ["inbox"].some(cb => remoteUserPermissions?.includes(cb)) && ["feature_inbox_visible"].some(cb => remoteUserFeatureFlags?.includes(cb)) : ["inbox"].some(cb => permissionDetails?.includes(cb)) && ["feature_inbox_visible"].some(cb => featureDetails?.includes(cb)) },
        { title: 'Manage Users', icon: <USBIconPeople size={64} colorVariant={sessionStorage.getItem('remote_user') ? 'grey80' : 'brand-primary'} title={'Manage Users'}></USBIconPeople>, url: '/ManageUser', visibility: sessionStorage.getItem('remote_user') ? (JSON.parse(sessionStorage.getItem('remote_user')).profileRole == "Site Administrator" || remoteUserPermissions?.includes("manageusers")) : (userCheck === 'employee'||userCheck === 'portaladmin'  || servicingUserRole === 'siteadmin' || servicingUserRole === 'vendorsiteadmin') && permissionDetails?.includes("manageusers") },
        { title: 'Document Templates', icon: <USBIconDocument size={64} colorVariant={'brand-primary'} title={'Document Template'}></USBIconDocument>, url: '/DocumentTemplate', visibility: sessionStorage.getItem('remote_user') ? ["inbox"].some(cb => remoteUserPermissions?.includes(cb)) && !["portfolio"].some(cb => remoteUserPermissions?.includes(cb)) ? false : true : ["inbox"].some(cb => permissionDetails?.includes(cb)) && !["portfolio"].some(cb => permissionDetails?.includes(cb)) ? false : true },
        { title: 'Reports', icon: <USBIconMail size={64} colorVariant={'brand-primary'} title={'Reports'}></USBIconMail>, url: '/Reports/CustomerAccessFilter', visibility: userCheck === 'employee' && !sessionStorage.getItem('remote_user') && permissionDetails?.includes("reports") },
        { title: 'Tools', icon: <USBIconSettings size={64} colorVariant={'brand-primary'} title={'Tools'}></USBIconSettings>, url: '/FeatureFlags', visibility: userCheck === 'employee' && !sessionStorage.getItem('remote_user') && ["tools"].some(item => permissionDetails?.includes(item)) },
    ]

    const contacts = [
        { name: 'test', email: 'test@usbank.com', mobile: '9090909090', profilePicture: <USBIconPerson size={48} colorVariant={'brand-primary'}></USBIconPerson> }
    ]

    const getRemoteUserPermissions = () => {
        callApi.getApiDataLoggedUser(`${endpoints.apigee}/document-management/coreservice/user-management/v1/allowedPages?role=${JSON.parse(sessionStorage.getItem('remote_user')).profileRole}`, '', {
            headers: {
                'Client-Application': 'CDC',
                'jwt': `${sessionStorage.getItem('tok')}`,
                'user-id': JSON.parse(sessionStorage.getItem('remote_user')).userID
            }
        }).then((permissions) => {
            if (permissions?.response?.status == 401) {
                if (confirm("Session Expired. Please login again") == true) {
                    onLogout()
                }
            } else {
                setRemoteUserPermissions(permissions.data)
            }
        })
    }

    const getRemoteUserFeatures = () => {
        callApi.getApiDataLoggedUser(`${endpoints.apigee}/document-management/coreservice/user-management/v1/get-all-features`, '', {
            headers: {
                'Client-Application': 'CDC',
                'jwt': `${sessionStorage.getItem('tok')}`,
                'user-id': JSON.parse(sessionStorage.getItem('remote_user')).userID
            }
        }).then((response) => {
            if (response?.response?.status == 401) {
                if (confirm("Session Expired. Please login again") == true) {
                    onLogout()
                }
            } else {
                let flags = [];
                response?.data?.forEach((flag) => {
                    if (flag.feature === 'feature_banner_visible') {
                        setRemoteUserFeatureFlags(flag)
                    }
                    if (
                        (flag.users.includes(sessionStorage.getItem('loggedUser')) ||
                            flag.users.includes('*')) &&
                        flag?.enable
                    ) {
                        flags.push(flag.feature);
                    }
                });

                setRemoteUserFeatureFlags(flags);
            }
        })
    }

    const removeImpersonatedForCB = (e) => {
        if (sessionStorage.getItem('remote_user')) {
            if (confirm("Are you sure you want to navigate to Closing Binders page? This will reset view to your profile if you were impersonating any user/viewing another user's profile.") == true) {
                sessionStorage.setItem('assignedUser', sessionStorage.getItem('loggedUser'));
                sessionStorage.removeItem('remote_user');
                window.location.href = '/ClosingBinders/Portfolio'
            }
        } else {
            window.location.href = '/ClosingBinders/Portfolio'
        }
    }

    const onLogout = () => {
        sessionStorage.removeItem("remote_user")
        sessionStorage.removeItem("portfolioFilters")
        if (userCheck !== 'employee') {
            window.location.href = '/';
            sessionStorage.clear();
        } else {
            sessionStorage.clear();
            window.location.href = '/EmployeeRedirect?tp=lg';
        }
    };

    const getCurrentExtendTime = () => {
        const currentIat =
            typeof sessionStorage !== 'undefined' ? checkIfSessionExists() : null;
        const sessionTimer = 600000;
        let refreshTime = currentIat * 1000 + sessionTimer - Date.now();
        if (refreshTime <= 60000) {
            refreshTime = 60000;
        }
        return refreshTime;
    };

    const authenticationPlugins = [
        '../../plugins/transmit/xmsdk.js',
        '../../plugins/transmit/xmui.js',
    ];

    const onSuccessChallenge = (response) => {
        console.log(response?.type);
        if (response && response?.type === 'logout') {
            if (sessionStorage.getItem('userrole') !== 'employee') {
                sessionStorage.clear();
                setCurrentToken(null);
                window.location.href = '/';
            } else {
                sessionStorage.clear();
                setCurrentToken(null);
                window.location.href = '/EmployeeRedirect?tp=sto';
            }
        } else {
            sessionStorage.setItem('tok', null);
            sessionStorage.setItem('tok', response.token);
            localStorage.setItem('tok', response.token);
            sessionStorage.setItem('at', null);
            sessionStorage.setItem(
                'at',
                parseJwt(sessionStorage.getItem('tok')).custom_data.at
            );
            sessionStorage.setItem(
                'currentSession',
                sessionStorage.getItem('currentSession')
            );
            setCurrentToken(response.token);

            const element = document.getElementById('manageuser-app');

            if (element) {
                const content = element.contentWindow;
                content.postMessage(
                    { type: 'token', data: response.token },
                    endpoints.manageUser
                );
            }
        }
    };

    const extendSessionSuccess = (response) => {
        sessionStorage.setItem('tok', null);
        sessionStorage.setItem('tok', response.token);
        localStorage.setItem('tok', response.token);
        sessionStorage.setItem('at', null);
        sessionStorage.setItem(
            'at',
            parseJwt(sessionStorage.getItem('tok')).custom_data.at
        );
        sessionStorage.setItem(
            'currentSession',
            sessionStorage.getItem('currentSession')
        );
        setCurrentToken(response.token);

        const element = document.getElementById('manageuser-app');

        if (element) {
            const content = element.contentWindow;
            content.postMessage(
                { type: 'token', data: response.token },
                endpoints.manageUser
            );
        }
    };

    const onIdleTimeoutLogoutSuccess = () => {
        window.onbeforeunload = null;
        if (sessionStorage.getItem('userrole') !== 'employee') {
            sessionStorage.clear();
            setCurrentToken(null);
            window.location.href = '/';
        } else {
            sessionStorage.clear();
            setCurrentToken(null);
            window.location.href = '/EmployeeRedirect?tp=sto';
        }
    };

    const onIdleTimeoutLogoutFailure = () => {
        window.onbeforeunload = null;
        if (sessionStorage.getItem('userrole') !== 'employee') {
            sessionStorage.clear();
            setCurrentToken(null);
            window.location.href = '/';
        } else {
            sessionStorage.clear();
            setCurrentToken(null);
            window.location.href = '/EmployeeRedirect?tp=sto';
        }
    };

    const checkIfSessionExists = () => {
        let parsedToken;
        if (sessionStorage.getItem('tok') !== null) {
            parsedToken = parseJwt(sessionStorage.getItem('tok')).iat;
        } else {
            parsedToken = null;
        }
        return parsedToken;
    };

    useEffect(() => {
        setCurrentToken(token);
        if (sessionStorage.getItem('remote_user')) {
            getRemoteUserPermissions()
            getRemoteUserFeatures()
        }
    }, [])


    useEffect(() => {
        if(featureDetails?.length>0){
        if(!featureDetails?.includes("feature_landing_page_visible")){
           window.location.href="/Redirect";
        }
    }
    }, [featureDetails])

    
    return (
        <>
            {assignedUserName.firstName != '' ? '' : <Loader></Loader>}
{ featureDetails?.length>0 && ["feature_landing_page_visible"].some(flag => featureDetails?.includes(flag))?
      <>      <GlobalHead title="Secure Document Portal" />
            <SessionHeader
                currentToken={currentToken}
                refreshTimeCount={getCurrentExtendTime()}
                authenticationPlugins={authenticationPlugins}
                extendSessionSuccess={extendSessionSuccess}
                onSuccessChallenge={onSuccessChallenge}
                onIdleTimeoutLogoutSuccess={onIdleTimeoutLogoutSuccess}
                onIdleTimeoutLogoutFailure={onIdleTimeoutLogoutFailure}
            />
            <BannerWarning></BannerWarning>
            <USBGrid addClasses="top-frame-landing">
                <USBColumn largeSpan={2} />
                <USBColumn largeSpan={12}>
                    <div className='landing-head'>
                        <div className="landingLogo" role="img" aria-label="US Bank Logo">
                            <img src={logo}></img>
                        </div>
                        <TopMenu onLogout={onLogout} />
                        {sessionStorage.getItem('remote_user') ? <div
                            className={'exitBtn'}
                            onClick={() => {
                                sessionStorage.removeItem('remote_user');
                                sessionStorage.removeItem('sessionFilters');
                                window.location.reload();
                                history.go(0);
                            }}
                        >
                            Exit User
                        </div> : ''}
                    </div>
                </USBColumn>
                <USBColumn largeSpan={2} />
            </USBGrid>
            <USBGrid addClasses="content-frame">
                <USBColumn largeSpan={16}>
                    {sessionStorage.getItem('remote_user') ? (
                        <div className="warning-panel-remote">
                            You are impersonating the user:{' '}
                            {JSON.parse(sessionStorage.getItem('remote_user')).firstName}{' '}
                            {JSON.parse(sessionStorage.getItem('remote_user')).lastName}. To
                            exit, click the 'Exit User' link above.
                        </div>
                    ) : (
                        ''
                    )}
                </USBColumn>
            </USBGrid>
            <USBGrid addClasses='content-frame'>

                <USBColumn largeSpan={2} />
                <USBColumn largeSpan={12}>
                    {sessionStorage.getItem('remote_user') ?
                        <div className="greetingsContainer">
                            <div>
                                Good {new Date().getHours() < 12 ? 'Morning,' : (new Date().getHours() > 12 && new Date().getHours() < 16) ? 'Afternoon,' : 'Evening,'} {JSON.parse(sessionStorage.getItem('remote_user')).firstName}{' '}{JSON.parse(sessionStorage.getItem('remote_user')).lastName}!
                            </div>
                            <div style={{ marginTop: '8px' }}>Welcome to the U.S. Bank Impact Finance Document Web Portal.</div>
                        </div>
                        :
                        sessionStorage.getItem('userrole') !== 'employee' ?
                            <div className="greetingsContainer">
                                <div>
                                    Good {new Date().getHours() < 12 ? 'Morning,' : (new Date().getHours() > 12 && new Date().getHours() < 16) ? 'Afternoon,' : 'Evening,'} {JSON.parse(sessionStorage.getItem('userData'))?.firstName}{' '}{JSON.parse(sessionStorage.getItem('userData'))?.lastName}!
                                </div>
                                <div style={{ marginTop: '8px' }}>Welcome to the U.S. Bank Impact Finance Document Web Portal.</div>
                            </div>
                            :
                            <div className='greetingsContainer'>
                                <div>
                                    Good {new Date().getHours() < 12 ? 'Morning,' : (new Date().getHours() > 12 && new Date().getHours() < 16) ? 'Afternoon,' : 'Evening,'} {assignedUserName.firstName == "You" ? userName.firstName != 'User' ? userName.firstName : "User" : assignedUserName.firstName}
                                    {(assignedUserName.firstName == "You" && userName.lastName != null) ? " " + userName.lastName : (assignedUserName.lastName === '' ||
                                        assignedUserName.lastName === null) ? (
                                        ''
                                    ) : (
                                        <span>&nbsp;</span>
                                    )}
                                    {assignedUserName.lastName}!
                                </div>

                                <div style={{ marginTop: '8px' }}>Welcome to the U.S. Bank Impact Finance Document Web Portal.</div>
                            </div>}

                    <div className='linksHeading'>Quick Links</div>
                    <div className='tilesContainer'>
                        {tiles.map((tile, index) => {
                            if (tile.visibility) {
                                return <div className={index == 3 && sessionStorage.getItem('remote_user') ? 'tile disableTile' : 'tile'} onClick={(e) => {
                                    if (index == 1) {
                                        removeImpersonatedForCB()
                                    } else if (index == 3 && sessionStorage.getItem('remote_user')) {
                                        alert("Manage Users is not accessible for impersonated user. Please exit the impersonated view to access the same.")
                                    } else {
                                        window.location.href = tile.url
                                    }
                                }}>
                                    <div className='tileIcon'>{tile.icon}</div>
                                    <div className='tileTitle'>{tile.title}</div>
                                </div>
                            } else {
                                return ''
                            }
                        })}
                    </div>
                    {/* <div className='contactsHeading'>Contacts</div>
                    <div className='contactsContainer'>
                        {contacts.map((contact) => {
                            return <div className='contactCard'>
                                <div className="contactImg">
                                    {contact.profilePicture}
                                </div>
                                <div className="contactBody">
                                    <div>{contact.name}</div>
                                    <div>{contact.email}</div>
                                    <div>{contact.mobile}</div>
                                </div>

                            </div>
                        })}
                    </div> */}

                </USBColumn>
                <USBColumn largeSpan={2} />
            </USBGrid>
            <GlobalFooter />
            </>
:''}
        </>
    )
}

export default LandingPage;