import React, { useEffect, useState } from 'react';
import { USBButton } from '@usb-shield/components-react';
import axios from 'axios';
import { endpoints } from '../../../config/connections';
import './Termsofuse.css';

const Termsofuse = () => {
  const [apiResponse, setApiResponse] = useState(0);

  const getTermsContent = () => {
    const termsUrl = `${endpoints.termsofuse}/en-us/esd/docmanagement/cdc-termsandconditions.model.json`;

    fetch(termsUrl)
      .then((response) => response.json())
      .then((res) => {
        // console.log('Response:: ', res[':items'].root[':items'].contentfragment.elements.termsandconditions.value)
        setApiResponse(
          res[':items'].root[':items'].contentfragment.elements
            .termsandconditions.value
        );
      });
  };

  const acceptTerms = () => {
    const jwtDSID = sessionStorage.getItem('tok');
    const accessToken = sessionStorage.getItem('at');
    const userId = sessionStorage.getItem('loggedUser');
    const roleId = sessionStorage.getItem('role');
    const token = sessionStorage.getItem('tok');

    const header = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      routingKey: endpoints.routingUpdateKey,
      'Correlation-ID': `${jwtDSID.sid}`,
      'Client-Data': `{"Role-ID": "${roleId}", "User-ID": "${userId}", "App-ID": "CDC", "Tenant-ID": "USB", "App-Version": "1", "Channel-ID": "web"}`,
      Accept: 'application/json',
      'Client-ID': 'CDC',
      'Service-Version': '2',
      SessionToken: token,
      Aid: 'cdc_web',
    };
    // const userId = sessionStorage.getItem('loggedUser')
    const accepturl = `${endpoints.apigee}/services/customer-management/servicing/v1`;
    const payload = {
      requestType: {
        serviceType: 'CDC_PROF_MAINT',
        serviceSubType: 'CREATE_TNC',
      },
      data: {
        intent: 'CREATE_TNC',
        userID: userId,
        productID: 'cdc-termsandconditions',
        sourceType: 'CDC',
      },
    };
    axios({
      method: 'post',
      url: accepturl,
      data: payload,
      headers: header,
    })
      .then((response) => {
        if (response.status === 200) {
          window.location.href = '/Redirect';          
        }
      })
      .catch((error) => {
        console.log(error);
        window.location.href = '/Error';
      });
  };

  const declineTerms = () => {
    window.location = '/';
  };

  useEffect(() => {
    getTermsContent();
  }, []);

  // https://stackoverflow.com/questions/39758136/render-html-string-as-real-html-in-a-react-component
  return (
    <>
      <div className="tou">
        <div className="tou-title">Terms of use</div>
        <div
          className="tou-container"
          dangerouslySetInnerHTML={{ __html: apiResponse }}
        />
        <div className="tou-button">
          <div className="single-buttons">
            <USBButton
              addClasses="decline-button"
              handleClick={() => declineTerms()}
            >
              Decline
            </USBButton>
          </div>
          <div className="single-buttons">
            <USBButton variant="primary" handleClick={acceptTerms}>
              Accept
            </USBButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default Termsofuse;
