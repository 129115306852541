const AssignPermissionHelper = {
    /**
     * this method should only be used to get which entitlement selected for frequent vendor.
     * return 'legal' | 'deal' | 'assignAll'
     */
    getSelectedEntitlmentForFrequentVendor: (assignedEntitlments) => {
        let legalExists = false;
        let dealExists = false;
        if (assignedEntitlments && assignedEntitlments.length > 0) {
            assignedEntitlments.forEach(parentCompany => {
                if (!legalExists) {
                    legalExists = parentCompany.reportingEntitys && parentCompany.reportingEntitys.length > 0 ? true : false;
                    if (legalExists == false) {
                        if (parentCompany.allReportingEntitysFlag == 'true') {
                            legalExists = true;
                        }
                    }
                }
                if (!dealExists) {
                    dealExists = parentCompany.deals && parentCompany.deals.length > 0 ? true : false;
                    if (dealExists == false) {
                        if (parentCompany.allDealsFlag == 'true') {
                            dealExists = true;
                        }

                    }
                }
                ///SKK NEED TO ADD LOGIC HERE 
            })
            if (legalExists && dealExists) {
                return 'assignAll'
            } else if (legalExists && !dealExists) {
                return 'legal';
            } else if (dealExists && !legalExists) {
                return 'deal';
            }
        }
        return undefined;

    },

    /**
     *  Build all the entitlement list for frequent vendor.
     * @param  state : 'create' | 'edit',
     * @param  assingedEntitlement: All the entitlements which are already assigned
     * @param allEntitlement: All the entitlments which can be assigned
     * return an object{
     *   documentsList: [],
     *      legalList: [],
     *       dealsList: 
     * }
     */
    buildAllEntitlementListForFrequentVendor: (state, allEntitlement, assingedEntitlement, previousSelectedItems = {}) => {
        let legalEntityList = [];
        let dealsList = [];
        let legalDocumentList = [];
        let dealDocumentsList = [];
        allEntitlement.forEach(parentCompany => {
            if (parentCompany.reportingEntitys) {

                parentCompany.reportingEntitys.forEach(re => {
                    const entity = { ...re };
                    entity.value = `${entity.value} | ${parentCompany.name}`
                    entity.selected = state === 'create' ? AssignPermissionHelper.isAlreadySelected(entity.value, previousSelectedItems.selectedLegalEntityList) : AssignPermissionHelper._isEntitleMentSelect('reportingEntitys', parentCompany.name, entity.key, assingedEntitlement);
                    legalEntityList.push(entity);
                });
            } if (parentCompany.deals) {

                parentCompany.deals.forEach(re => {
                    const entity = { ...re };
                    entity.value = `${entity.value} | ${parentCompany.name}`;
                    entity.selected = state === 'create' ? AssignPermissionHelper.isAlreadySelected(entity.value, previousSelectedItems.selectedDealEntityList) : AssignPermissionHelper._isEntitleMentSelect('deals', parentCompany.name, entity.key, assingedEntitlement);
                    dealsList.push(entity);
                });
            }
            if (parentCompany.categories) {
                parentCompany.categories.forEach(re => {
                    if(re.value=="") re.value = re.key;
                    const alreadyExist = legalDocumentList.findIndex(doc => doc.key === re.key  || doc.key === (re.key + "").split(" - ")[0] || re.key === (doc.key + "").split(" - ")[0]);
                    let tempList = legalDocumentList.map(category => {
                        if(category.key?.includes("-")){
                            let tempStr = category.key.split(" - ")
                            return {key : tempStr[0], value:tempStr[1], selected : category.selected}
                        } else {
                            return category
                        }
                    })
                    if (re.value && (alreadyExist < 0 || !tempList[alreadyExist]?.selected)) {
                        let tempRe = {...re};
                        if(re.key?.includes("-")){
                            let tempStr = re.key.split(" - ")
                            tempRe = { key : tempStr[0] , value : tempStr[1] , selected : re.selected}
                        }
                        const entity = { ...tempRe };
                        entity.selected = state === 'create' ? AssignPermissionHelper.isAlreadySelected(entity.value, previousSelectedItems.selectedEntiyDocumentList) : AssignPermissionHelper._isEntitleMentSelect('categories', parentCompany.name, entity.key, assingedEntitlement);
                        if (alreadyExist < 0) {
                            legalDocumentList.push(entity);
                        } else {
                            legalDocumentList[alreadyExist] = entity;
                        }
                    }
                });
                parentCompany.categories.forEach(re => {
                    if(re.value=="") re.value = re.key;
                    const alreadyExist = dealDocumentsList.findIndex(doc => doc.key === re.key  || doc.key === (re.key + "").split(" - ")[0] || re.key === (doc.key + "").split(" - ")[0]);
                    let tempList = legalDocumentList.map(category => {
                        if(category.key?.includes("-")){
                            let tempStr = category.key.split(" - ")
                            return {key : tempStr[0], value:tempStr[1], selected : category.selected}
                        } else {
                            return category
                        }
                    })
                    if (re.value && (alreadyExist < 0 || !tempList[alreadyExist]?.selected)) {
                        let tempRe = {...re};
                        if(re.key?.includes("-")){
                            let tempStr = re.key.split(" - ")
                            tempRe = { key : tempStr[0] , value : tempStr[1] , selected : re.selected}
                        }
                        const entity = { ...tempRe };
                        entity.selected = state === 'create' ? AssignPermissionHelper.isAlreadySelected(entity.value, previousSelectedItems.selectedDealDocumentList) : AssignPermissionHelper._isEntitleMentSelect('categories', parentCompany.name, entity.key, assingedEntitlement);
                        if (alreadyExist < 0) {
                            dealDocumentsList.push(entity);
                        } else {
                            dealDocumentsList[alreadyExist] = entity;
                        }
                    }
                });
            }
        });
        return {
            legalDocumentList: legalDocumentList,
            dealDocumentList: dealDocumentsList,
            legalList: legalEntityList,
            dealsList: dealsList
        }
    },
    /**
     * Check if the entitlement key is present in allAssignedEntitlments for the given parent Company.
     * @param  entitlement : 'legal' | 'deal' | 'categories',
     * @param parentCompany: ParentCompanyName.
     * @param entitlementKey : Key of entitlement which needs to be searched
     * @param allAssignedEntitlments: All the assigned entitlements
     * return : boolean
     */
    _isEntitleMentSelect: (entitlement, parentCompany, entitlementKey, allAssignedEntitlments) => {
        let entitlmentExists = false;
        allAssignedEntitlments.forEach(parentEntity => {
            if (parentEntity.name === parentCompany && parentEntity[entitlement] && parentEntity[entitlement].length > 0) {
                const matched = parentEntity[entitlement].find(e => e.key?.includes("-") ? e.key.split(" - ")[0] == entitlementKey : entitlementKey?.includes("-") ? e.key==entitlementKey.split(" - ")[0] : e.key === entitlementKey);
                if (matched && matched.key) {
                    entitlmentExists = true;
                }
            }
        })
        return entitlmentExists;
    },

    isAlreadySelected: (entity, itemsList) => {
        let tempEntity = {...entity};
        if (itemsList && itemsList.length > 0) {
            let tempItemsList = itemsList.map(item => {
                if((item.key+"").includes("-")){
                    let tempItem = (item.key+"").split(" - ")
                    return { key : tempItem[0], value : tempItem[1]}
                } else if((item.value=="" || item.value==undefined) && item.key!=""){
                    return { key : item.key, value:item.key}
                } else if(item.value!="" && item.key==""){
                    return { key : item.value, value:item.value}
                }
                return item;
            })
            if((entity.key+"").includes("-")){
                let tempArr = (entity.key + "").split(" - ")
                tempEntity = {key : tempArr[0], value : tempArr[1]}
            }else if((entity.value=="" || entity.value==undefined) && entity.key!=""){
                tempEntity = { key : entity.key, value:entity.key}
            } else if(entity.value!="" && entity.key==""){
                tempEntity = { key : entity.value, value:entity.value}
            }
            return tempItemsList.some(item => item.value === tempEntity.value && item.key == tempEntity.key);
        }
        return false;
    },

    isFrequentVendor: (isFrequentVendor, parentCompany, initialProfileData) => {
        return isFrequentVendor ||
            (parentCompany?.businessProfileType?.toLowerCase() === "vendor" || parentCompany?.businessProfileType?.toLowerCase() === 'specialvendor')
            || (initialProfileData?.usbRelationship === 'Frequent_Vendor_Contributor__Contact' || initialProfileData?.usbRelationship === 'Frequent_Vendor_Site_administrator__Contact')
    },

    /**
     *  NEWLY ADDED NON LISTED VENDOR
     * @param  state : 'create' | 'edit',
     * @param  assingedEntitlement: All the entitlements which are already assigned
     * @param allEntitlement: All the entitlments which can be assigned
     * return an object{
     *   documentsList: [],
     *      legalList: [],
     *       dealsList: 
     * }
     */
    buildAllEntitlementListForUnListedVendor: (state, allEntitlement, assingedEntitlement, previousSelectedItems = {}) => {
        let legalEntityList = [];
        let dealsList = [];
        let legalDocumentList = [];
        let dealDocumentsList = [];
        allEntitlement.forEach(parentCompany => {
            if (parentCompany.reportingEntitys) {

                parentCompany.reportingEntitys.forEach(re => {
                    const entity = { ...re };
                    entity.value = `${entity.value} | ${parentCompany.name}`
                    entity.selected = true;
                    legalEntityList.push(entity);
                });
            } if (parentCompany.deals) {

                parentCompany.deals.forEach(re => {
                    const entity = { ...re };
                    entity.value = `${entity.value} | ${parentCompany.name}`;
                    entity.selected = true;
                    dealsList.push(entity);
                });
            }
            if (parentCompany.categories) {
                parentCompany.categories.forEach(re => {
                    const alreadyExist = legalDocumentList.findIndex(doc => doc.key === re.key || doc.key === (re.key + "").split(" - ")[0] || re.key === (doc.key + "").split(" - ")[0]);
                    if (re.value && (alreadyExist < 0 || !legalDocumentList[alreadyExist]?.selected)) {

                        const entity = { ...re };
                        entity.selected = true;
                        if (alreadyExist < 0) {
                            legalDocumentList.push(entity);
                        } else {
                            legalDocumentList[alreadyExist] = entity;
                        }
                    }
                });
                parentCompany.categories.forEach(re => {
                    const alreadyExist = dealDocumentsList.findIndex(doc => doc.key === re.key  || doc.key === (re.key + "").split(" - ")[0] || re.key === (doc.key + "").split(" - ")[0]);
                    if (re.value && (alreadyExist < 0 || !dealDocumentsList[alreadyExist]?.selected)) {

                        const entity = { ...re };
                        entity.selected = true;
                        if (alreadyExist < 0) {
                            dealDocumentsList.push(entity);
                        } else {
                            dealDocumentsList[alreadyExist] = entity;
                        }
                    }
                });
            }
        });
        return {
            legalDocumentList: legalDocumentList,
            dealDocumentList: dealDocumentsList,
            legalList: legalEntityList,
            dealsList: dealsList
        }
    }
}

export default AssignPermissionHelper;